import React, { useState, useEffect } from 'react';
import './CreateTemplate.css'; // Import the CSS file
import { FaBullhorn, FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle, FaImage, FaVideo, FaFileAlt, FaMapMarkerAlt, FaTextHeight, FaMinusCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import HeaderSection from './HeaderSection';
import BodySection from './BodySection';
import FooterSection from './FooterSection';
import ButtonSection from './ButtonSection';
import ErrorModal from '../ErrorModal/ErrorModal';
import ChatPreview from '../ChatPreview/ChatPreview';
import { getToken } from '../ApiService/ApiService';
import { createWATemplate } from '../ApiService/ApiService';
import Modal from 'react-modal'; // Example modal library

const CreateTemplate = ({ onBackClick, selectedCategory }) => {
    const [selectedOption, setSelectedOption] = useState('None');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [text, setText] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [file, setFile] = useState(null);
    const [mainSelection, setMainSelection] = useState('');
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [templateName, setTemplateName] = useState('Your_template_name');
    const [language, setLanguage] = useState('en_US');
    const [buttons, setButtons] = useState([]);
    const [secondaryOption, setSecondaryOption] = useState('');
    const [inputText, setInputText] = useState('');
    const [message, setMessage] = useState(''); // Renamed from 'text' to 'message'
    const [variablesList, setVariablesList] = useState([]); // Renamed from 'variables'
    const [variableCount, setVariableCount] = useState(1); // Renamed from 'nextVariableIndex'
    const [bodyText, setBodyText] = useState('');
    const [variableFields, setVariableFields] = useState([]);
    const [nextVariableIndex, setNextVariableIndex] = useState(1);
    const [variableHeaderAdded, setVariableHeaderAdded] = useState(false);
    const [variableText, setVariableText] = useState(''); // Text for the variable
    const [variableHeaderValue, setVariableHeaderValue] = useState(''); // Value of the variable
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
     const [showSuccess, setShowSuccess] = useState(false);
    const [headerImageResponse, setHeaderImageResponse] = useState(null);
    const [selectedHeaderOption, setSelectedHeaderOption] = useState('');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [headerFile, setHeaderFile] = useState(null);
    const [headerVariableAdded, setHeaderVariableAdded] = useState(false);
    const [headerVariableValue, setHeaderVariableValue] = useState('');
    const options = [
        { value: 'None', label: 'None' },
        { value: 'Image', label: 'Image', icon: <FaImage /> },
        { value: 'Text', label: 'Text', icon: <FaTextHeight /> },
        { value: 'Video', label: 'Video', icon: <FaVideo /> },
        { value: 'Document', label: 'Document', icon: <FaFileAlt /> },
        { value: 'Location', label: 'Location', icon: <FaMapMarkerAlt /> },
    ];

    const [headerVideoResponse, setHeaderVideoResponse] = useState(null);
    const [headerDocumentResponse, setHeaderDocumentResponse] = useState(null);

    const [headerVariableFields, setHeaderVariableFields] = useState([]);
    const [variableAdded, setVariableAdded] = useState(false);
    const [variableValue, setVariableValue] = useState('');

    const [imageUrl, setImageUrl] = useState(null);
    // Add a new variable for the header


    // Handle variable value change for header


    const handleVariableAdd = () => setVariableAdded(true);
    const handleVariableRemove = () => {
        setVariableAdded(false);
        setVariableValue('');
    };

    // const handleHeaderVariableChange = (value) => setVariableValue(value);


    const handleHeaderVariableChange = (newValue) => {
        setVariableValue(newValue); // This updates the state correctly
    };


    // Function to add variable


    const handleHeaderTextChange = (e) => {
        setHeaderText(e.target.value);
    };

    // Function to add a header variable
    const handleHeaderVariableAdd = () => {
        setHeaderVariableFields([{ value: '' }]); // Initialize with an empty variable value
    };

    // Function to remove a header variable
    const handleHeaderVariableRemove = () => {
        setHeaderVariableFields([]); // Clear variable fields when removing
    };

    // Function to update the header variable value in the state

    const handleImageUploadSuccess = (response) => {
        // console.log('Image Upload Success:', response);
        setHeaderImageResponse(response.data); // Ensure you're saving the correct ID or response
    };

    // Function to handle video upload success
    const handleVideoUploadSuccess = (response) => {
        // console.log('Video Upload Success:', response);
        setHeaderVideoResponse(response.data); // Ensure you're saving the correct ID or response
    };

    // Function to handle document upload success
    const handleDocumentUploadSuccess = (response) => {
        // console.log('Document Upload Success:', response);
        setHeaderDocumentResponse(response.data); // Ensure you're saving the correct ID or response
        // console.log("docid:", response.data)
    };

    const handleOptionChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);

        if (value !== 'Text') {
            setHeaderText('');
        }

        if (['Image', 'Video', 'Document'].includes(value)) {
            document.getElementById('fileInput').click();
        }
    };

    const handleFileChange = (url) => {
        setImageUrl(url);
    };



    //BODY FUNCTIONS
    const extractVariables = (text) => {
        const regex = /{{(\d+)}}/g;
        const matches = [...text.matchAll(regex)];
        const indexes = matches.map((match) => parseInt(match[1], 10));
        // Sort indexes in ascending order
        indexes.sort((a, b) => a - b);
        return indexes;
    };


    useEffect(() => {
        // Extract variables whenever bodyText changes
        const detectedVariables = extractVariables(bodyText);

        setVariableFields((prevVariables) => {
            const currentVariableIndexes = prevVariables.map((variable) => variable.index);
            const newVariables = detectedVariables.filter((index) => !currentVariableIndexes.includes(index));
            const updatedVariables = [
                ...prevVariables,
                ...newVariables.map((index) => ({ index, value: '' })),
            ];

            // Remove variables that are not in the bodyText anymore
            return updatedVariables.filter((variable) => detectedVariables.includes(variable.index));
        });

        // Update next variable index to the highest current + 1
        const maxIndex = detectedVariables.length > 0 ? Math.max(...detectedVariables) : 0;
        setNextVariableIndex(maxIndex + 1);
    }, [bodyText]);


    const handleAddVariable = () => {
        const newVariable = `{{${nextVariableIndex}}}`;
        setBodyText((prevBodyText) => prevBodyText + ' ' + newVariable);
        setNextVariableIndex((prevIndex) => prevIndex + 1);
    };


    const handleDeleteVariable = (index) => {
        setVariableFields((prevVariables) =>
            prevVariables.filter((variable) => variable.index !== index)
        );
        setBodyText((prevBodyText) => prevBodyText.replace(`{{${index}}}`, ''));
    };



    const handleVariableChange = (index, value) => {
        setVariableFields((prevVariables) =>
            prevVariables.map((variable) =>
                variable.index === index ? { ...variable, value } : variable
            )
        );
    };



    // const handleSelectChange = (event) => {
    //     const value = event.target.value;
    //     setMainSelection(value);
    //     // Update `showAdditionalFields` based on the selected value
    //     setShowAdditionalFields(value === 'option1'); // Show additional fields if 'Quick Reply' is selected
    // };


    const handleSelectChange = (event) => {
        const value = event.target.value;
        setMainSelection(value);
        setShowAdditionalFields(value === 'option1'); // Adjust according to the selected value
    };




    const handleSecondaryOptionChange = (event) => {
        setSecondaryOption(event.target.value);
    };

    const handleInputTextChange = (event) => {
        setInputText(event.target.value);
    };


    const handleAction = (action) => {
        // console.log(`${action} button clicked`);
    };
    const addButton = () => {
        if (mainSelection === 'option1' && inputText.trim()) {
            const newButton = {
                type: secondaryOption || 'QUICK_REPLY',
                text: inputText,
            };
            setButtons([...buttons, newButton]);
            // Clear input fields after adding button
            setSecondaryOption('');
            setInputText('');
        }
    };

    const handleUpdateButtons = (updatedButtons) => {
        setButtons(updatedButtons);
        // console.log('Updated buttons:', updatedButtons); 
    };

    const token = getToken();  // Get the token using the getToken function
    // console.log('Stored Token:', token); // Log the token to check its presence



    const generateJSON = (userId) => {
        // console.log("Header Document Response in generateJSON:", headerDocumentResponse);

        const exampleBodyText = variableFields.length > 0
            ? [variableFields.map((variable) => variable.value)]
            : [];

        const components = [];

        // Add HEADER component based on selectedOption and responses
        if (selectedOption === 'Text' && headerText) {
            components.push({
                type: 'HEADER',
                format: 'TEXT',
                text: headerText,
                ...(variableAdded ? {
                    example: { header_text: [variableValue] },
                } : {}),
            });
        } else if (selectedOption === 'Image' && headerImageResponse) {
            components.push({
                type: 'HEADER',
                format: 'IMAGE',
                example: {
                    header_handle: [headerImageResponse.h],
                },
            });
        } else if (selectedOption === 'Video' && headerVideoResponse) {
            components.push({
                type: 'HEADER',
                format: 'VIDEO',
                example: {
                    header_handle: [headerVideoResponse.id],
                },
            });
        } else if (selectedOption === 'Document' && headerDocumentResponse) {
            // console.log("Document ID being pushed:", headerDocumentResponse.h);
            components.push({
                type: 'HEADER',
                format: 'DOCUMENT',
                example: {
                    header_handle: [headerDocumentResponse.h],
                },
            });
        } else if (selectedOption === 'Location') { // Add Location option here
            components.push({
                type: 'HEADER',
                format: 'LOCATION',
            });
        }

        // Add BODY component
        components.push({
            type: 'BODY',
            text: bodyText || 'it is new one{{1}}',
            ...(exampleBodyText.length > 0
                ? { example: { body_text: exampleBodyText } }
                : {}),
        });

        // Add FOOTER component if footerText exists
        if (footerText) {
            components.push({
                type: 'FOOTER',
                text: footerText || 'Footer text',
            });
        }

        // Add BUTTONS if any buttons are present
        if (buttons.length > 0) {
            components.push({
                type: 'BUTTONS',
                buttons: buttons.map((button) => ({
                    type: button.type || 'QUICK_REPLY',
                    text: button.text || 'Default Button Text',
                    ...(button.url && { url: button.url }),
                    ...(button.offerCode && { offerCode: button.offerCode }),
                    ...(button.phone_number && { phone_number: button.phone_number }),
                })),
            });
        }

        const jsonData = {
            name: templateName,
            code: {
                name: templateName,
                language: language,
                category: selectedCategory || 'MARKETING',
                components: components,
            },
            userid: userId,
        };

        // console.log("Final JSON Payload:", JSON.stringify(jsonData, null, 2));
        return jsonData;
    };






    const refreshToken = async () => {
        try {
            const response = await axios.post('https://api.example.com/refresh-token', {
                // Provide necessary details to refresh the token
            });

            // Save the new token
            localStorage.setItem('token', response.data.token);
            return response.data.token;
        } catch (error) {
            console.error('Error refreshing token:', error.message);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(null);

        const token = getToken(); // Get the token using the getToken function
        // console.log('Stored Token:', token);

        if (!bodyText.trim()) {
            setErrorMessage('Body text is required'); // Set error message for empty body text
            setShowError(true); // Show error modal
            setLoading(false); // Stop loading
            return; // Stop execution if body text is empty
        }

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const isTokenExpired = new Date().getTime() / 1000 > decodedToken.exp; // Token expiry check

                if (isTokenExpired) {
                    token = await refreshToken();
                }

                // console.log('Header Document Response:', headerDocumentResponse);
                const jsonData = generateJSON(decodedToken.id); // Pass the user ID to the JSON generator

                const response = await createWATemplate(jsonData); // Using the API function from apiService
                // console.log('API response:', response);

                // Show success modal instead of alert
                setLoading(false); // Hide loader
                setShowSuccessModal(true); // Show modal

                // Navigate after a short delay
                setTimeout(() => {
                    navigate('/main/send-template');
                }, 1500); // Optional: Adjust delay duration for better UX
            } catch (error) {
                console.error('Error occurred while sending data:', error.message);
                console.error('API error message:', error.response?.data?.message);
                console.error('API error details:', error.response?.data?.error);
                setErrorMessage(error.response?.data?.message); // Set the error message from the response
                setShowError(true); // Show the error modal
            }
        } else {
            console.error('Token not found.');
            // Handle missing token scenario
        }
        setLoading(false); // Make sure to stop loading in all cases
    };

    const handleCloseModal = () => {
        setShowError(false);
        setErrorMessage('');
    };




    return (
        <>
            <div className="albody">
                <h4 style={{ paddingTop: '0px', color: '#00417d' }}>Create Template</h4>
                <div className="containernext">

                    <div className="left-side">
                        <div className="box" style={{ padding: '5px 20px' }}>
                            <div className="headig">
                                <div className="icon-container">
                                    <FaBullhorn className="icon1" />
                                </div>
                                <div className="text-container">
                                    <h3 className='hhh2'>{templateName} • {language}</h3>
                                    <p>Marketing • Custom</p>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="">
                                <h5>Template name and language</h5>
                                <div className="template-input">
                                    <p className='text11'>Name your Template</p>
                                    <div className="input-group">
                                        <label className="input-label" htmlFor="templateName">Template Name</label>
                                        <label className="input-label" htmlFor="selectLanguage">Select Language</label>
                                        <input
                                            id="templateName"
                                            type="text"
                                            placeholder="Enter a template name"
                                            maxLength={512}
                                            className="template-input-field"
                                            value={templateName}
                                            onChange={(e) => {
                                                // Replace spaces with underscores and restrict to allowed characters
                                                const formattedValue = e.target.value
                                                    .replace(/\s+/g, '_') // Replace spaces with underscores
                                                    .replace(/[^a-zA-Z0-9_]/g, '') // Remove all characters except alphabets, numbers, and underscores
                                                    .toLowerCase(); // Convert to lowercase
                                                setTemplateName(formattedValue);
                                            }}
                                        />
                                        <select id="selectLanguage" className="language-select" value={language} onChange={(e) => setLanguage(e.target.value)}>
                                            <option value="en_US">en_US</option>
                                            <option value="Spanish">Spanish</option>
                                            <option value="French">French</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="">
                                <h5>Content</h5>

                                <p>Fill out your header, body and footer section of your template</p>
                                <HeaderSection
                                    selectedOption={selectedOption}
                                    headerText={headerText}
                                    onOptionChange={handleOptionChange}
                                    onHeaderTextChange={handleHeaderTextChange}
                                    onFileChange={handleFileChange}
                                    file={file}
                                    onVariableAdd={handleVariableAdd}
                                    onVariableRemove={handleVariableRemove}
                                    onVariableChange={handleHeaderVariableChange}
                                    variableAdded={variableAdded}
                                    variableValue={variableValue}
                                    onImageUploadSuccess={handleImageUploadSuccess}
                                    onVideoUploadSuccess={handleVideoUploadSuccess}
                                    onDocumentUploadSuccess={handleDocumentUploadSuccess}
                                />


                                <BodySection
                                    bodyText={bodyText}
                                    onBodyTextChange={(e) => setBodyText(e.target.value)}
                                    handleAddVariable={handleAddVariable}
                                    variableFields={variableFields}
                                    handleVariableChange={handleVariableChange}
                                    handleDeleteVariable={handleDeleteVariable}
                                />
                                <FooterSection
                                    footerText={footerText}
                                    onFooterTextChange={(e) => setFooterText(e.target.value)}
                                />

                            </div>

                        </div>
                        <div className="box"><ButtonSection
                            mainSelection={mainSelection}
                            showAdditionalFields={true}
                            secondaryOption={secondaryOption}
                            inputText={inputText}
                            buttons={buttons}
                            handleSelectChange={(e) => setMainSelection(e.target.value)}
                            handleSecondaryOptionChange={(e) => setSecondaryOption(e.target.value)}
                            handleInputTextChange={(e) => setInputText(e.target.value)}
                            addButton={() => console.log('**')} // Optional: Example
                            onUpdateButtons={handleUpdateButtons} // Receive updated button data
                        />

                        </div>
                    </div>
                    <div className="right-side sticky-preview" style={{ position: 'sticky' }}>
                        <div className="box-hgsh">

                            <h5>Preview Template</h5>
                            <p>lively preview your template here</p>
                        </div>
                        <div className="box" style={{ padding: '10px' }}>
                            <div className="">
                                {/* Preview Template Section */}


                                <div className="">

                                    <ChatPreview
                                        headerText={headerText}
                                        bodyText={bodyText}
                                        footerText={footerText}
                                        buttons={buttons}
                                        showAdditionalButton={false}
                                        additionalButtonText=""
                                        imageUrl={imageUrl}
                                    />


                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <div className="buttons-container12">
                    <button className="start-button" onClick={onBackClick}>Previous</button>
                    <button type="submit" className="review-button" onClick={handleSubmit}>Submit


                    </button>

                </div>
              
            </div>
            {loading && (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            )}
            {ErrorModal && (
                <ErrorModal
                    showError={showError}
                    errorMessage={errorMessage}
                    onClose={handleCloseModal}
                />
            )}
            <Modal
                isOpen={showSuccessModal}
                onRequestClose={() => setShowSuccessModal(false)}
                contentLabel="Success Modal"
                className="success-modalsus"
                overlayClassName="modal-overlaysus"
            >
                <h2 style={{ color: 'green', marginBottom: '20px' }} className='mdh2ta'>Template Created Successfully!</h2>
                <button
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#00417d',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowSuccessModal(false)}
                >
                    Close
                </button>
            </Modal>
        </>
    );
};

export default CreateTemplate;