import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../ApiService/ApiService';
import { jwtDecode } from 'jwt-decode';
import './Login.css';
import loginImage from '../../assets/images/webbi.png';
import logoImage from '../../assets/whatsapp-logo.svg';

const Modal = ({ visible, content, onClose }) => {
    if (!visible) return null;

    return (
        <div className="modal-overlaylo">
            <div className="modal-contentlo">
                <p>{content}</p>
                <button className="btn1" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const appId = "981071426908731";
    const configId = "1564406280945997";

    const handleLogin = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        const payload = { email, password };
 console.log("emailsnf password", payload)
        loginUser(payload)
            .then((data) => {
                console.log('Login Success:', data);

                // Store the token in local storage


                // Decode the token and get user ID
                const token = data.token;
                const decoded = jwtDecode(token);
                const userId = decoded.userId;
                console.log('User ID:', userId);

                // Navigate to main layout
                navigate('/main');
            })
            .catch((err) => {
                console.error('Login Error:', err.message);
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: appId,
                cookie: true,
                xfbml: true,
                version: "v2.7",
            });
        };
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, [appId]);

    const handleReg = () => {
        // setModalContent('If the process takes longer than 5 seconds, please refresh the page and try again. Thank you for your patience.');
        // setModalVisible(true);

        window.FB.login(
            function (response) {
                console.log("Facebook Response:", response);
                if (response.status === "connected") {
                    const authCode = response.authResponse?.code;
                    if (authCode) {
                        setModalContent('Processing Facebook login...');
                        fetch("https://api.whatsappalerts.com:3007/api/v1/embedded/exchange-code", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ code: authCode }),
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    return res.json();
                                } else {
                                    throw new Error("Failed to process login.");
                                }
                            })
                            .then((data) => {
                                const verified_name = data.verified_name;
                                if (verified_name) {
                                    setModalContent(`Welcome, ${verified_name}! Registration successful.Verification is in progress.We'll notify you once it's complete.`);
                                } else {
                                    setModalContent("No verified name found in the response.");
                                }
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                                setModalContent("An error occurred during Facebook login. Please try again.");
                            });
                    } else {
                        setModalContent("Facebook login was not successful. Please try again.");
                    }
                } else {
                    setModalContent("Facebook login was not successful. Please try again.");
                }
            },
            {
                config_id: configId,
                response_type: "code",
                override_default_response_type: true,
            }
        );
    };

    return (
        <>
            <div className="login1">
                <div className="logo-container">
                    <img src={logoImage} alt="Logo" className="logo-image" />
                    <h1 className="site-name">iissms-whatsapp</h1>
                </div>
                <div className="login-container">
                    <div className="login-right">
                        <h2 className="login-title">Let's you sign in</h2>
                        <p className="login-subtitle">
                            Welcome to our Page
                            <a href="#" className="logfb" onClick={(e) => { e.preventDefault(); handleReg(); }}>
                                Login with Facebook
                            </a>
                        </p>
                        <form onSubmit={handleLogin} className="login-form">
                            <div className="form-grouplogin">
                                <label>Username:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-grouplogin">
                                <label>Password:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn1 btn-block"
                                disabled={loading}
                            >
                                {loading ? 'Signing in...' : 'Sign In'}
                            </button>
                        </form>
                        {error && <p className="error-message">{error}</p>}
                    </div>
                    <div className="login-left">
                        <img src={loginImage} alt="Login Illustration" className="login-image" />
                    </div>
                </div>
            </div>
            <Modal
                visible={modalVisible}
                content={modalContent}
                onClose={() => setModalVisible(false)}
            />
        </>
    );
};

export default Login;