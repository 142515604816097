import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import ModifyTemplate from '../ModifyTemplate/ModifyTemplate'
import './CreateWhatsappTemplate.css';
import { getWAApTemplates } from '../ApiService/ApiService';
import { postTemplateMapping } from '../ApiService/ApiService';
import TokenService from '../Tokens/tokenservice';
const CreateWhatsappTemplate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams(); // Extracting id from URL
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedCode, setSelectedCode] = useState(null);
    const [mappingData, setMappingData] = useState(null);
    const [selectedHeaderHandlerId, setSelectedHeaderHandlerId] = useState(null); // Add this

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const query = new URLSearchParams(location.search);
    const templateId = query.get('template_id'); // Extracting template_id from query parameters

    const replacePlaceholders = (text, example) => {
        if (!example) return text;
        let replacedText = text;
        example.forEach((ex, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, ex);
        });
        return replacedText;
    };

    const getUserIdFromToken = () => {
        const token = TokenService.getToken();
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id;
            } catch (err) {
                console.error('Error decoding token:', err);
            }
        }
        return null;
    };



    const fetchTemplates = async () => {
        const userId = getUserIdFromToken();  // Assuming this function gets the user ID from the token
        if (!userId) {
            setError('Invalid token or user ID not found');
            setLoading(false);
            return;
        }

        try {
            const templatesData = await getWAApTemplates(userId);  // Use the API function to fetch templates

            const templatesWithPreview = templatesData.templates.map((template) => {
                let header = '';
                let body = '';
                let footer = '';
                let button = '';

                try {
                    const code = JSON.parse(template.code || '{}');
                    code.components.forEach((component) => {
                        switch (component.type) {
                            case 'HEADER':
                                header = replacePlaceholders(component.text, component.example?.header_text || []);
                                break;
                            case 'BODY':
                                body = replacePlaceholders(component.text, component.example?.body_text?.flat() || []);
                                break;
                            case 'FOOTER':
                                footer = replacePlaceholders(component.text, component.example?.footer_text || []);
                                break;
                            case 'BUTTONS':
                                button = component.buttons.map((b) => b.text).join(', ');
                                break;
                            default:
                                break;
                        }
                    });
                } catch (e) {
                    console.error('Error parsing code:', e);
                }

                return {
                    ...template,
                    header,
                    body,
                    footer,
                    button,
                };
            });

            setTemplates(templatesWithPreview);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching templates:', err);
            setError('Failed to fetch templates');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const getStatusClass = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'status-approved';
            case 'REJECTED':
                return 'status-rejected';
            case 'PENDING':
                return 'status-pending';
            default:
                return '';
        }
    };
    const handleDropdownChange = async (event) => {
        const selectedId = event.target.value;

        // Reset the state variables if the selected template changes
        if (selectedId !== selectedTemplateId) {
            setSelectedTemplate(null);
            setSelectedCode(null);
            setSelectedHeaderHandlerId(null); // Reset header handler ID
        }

        setSelectedTemplateId(selectedId);
        if (!selectedId) return;

        setLoading(true); // Show loader

        const template = templates.find((tpl) => String(tpl.id) === String(selectedId));
        if (!template) {
            setMappingData(null);
            return;
        }

        let headerHandlerId = null;

        try {
            const code = JSON.parse(template.code || '{}');
            const headerComponent = code.components.find(
                (comp) => comp.type === 'HEADER' && comp.format === 'IMAGE'
            );

            if (headerComponent && headerComponent.example?.header_handle) {
                headerHandlerId = headerComponent.example.header_handle[0];
            }
        } catch (e) {
            console.error('Error parsing template code:', e);
        }

        const userId = getUserIdFromToken();
        const waTemplateId = template.id;

        if (waTemplateId && userId && templateId) {
            try {
                const mappingData = await postTemplateMapping(templateId, waTemplateId); // Call the API function
                setMappingData(mappingData); // Only set this if mapping is successful

                // If mapping is successful, update state variables
                setSelectedTemplate(template);
                setSelectedCode(template.code);
                setSelectedHeaderHandlerId(headerHandlerId);
                setTimeout(() => {
                    setLoading(false); // Hide loader
                    setModalMessage('Template selected successfully!'); // Set modal message
                    setTimeout(() => setShowModal(false), 2000); // Hide modal after 2 seconds
                }, 2000); // Simulate 2 seconds of loader
            } catch (error) {
                console.error('Error fetching mapping data:', error.response ? error.response.data : error.message);

                // Set default values to ModifyTemplate in case of an error
                setSelectedTemplate({
                    name: 'Default Template Name',
                    wa_template_status: 'PENDING',
                    wa_template_category: 'General',
                });
                setSelectedCode('{}'); // Default empty code
                setSelectedHeaderHandlerId(null); // Default empty handler ID

                alert('Failed to map the data. Defaults have been applied.');
                setLoading(false); // Hide loader in case of error
            }
        } else {
            console.error('Missing wa_template_id or userId');
            setError('Missing wa_template_id or userId');

            // Set default values to ModifyTemplate in case of an error
            setSelectedTemplate({
                name: 'Default Template Name',
                wa_template_status: 'PENDING',
                wa_template_category: 'General',
            });
            setSelectedCode('{}'); // Default empty code
            setSelectedHeaderHandlerId(null); // Default empty handler ID

            alert('Failed to map the data. Defaults have been applied.');
            setLoading(false); // Hide loader
        }
    };




    const handleCreateNewTemplate = () => {
        navigate('/main/create-template');
    };

    return (
        <>
            <div className="cwt">
                <div className="box part1">
                    <h5>Create WhatsApp Template Here</h5>
                    <p className='dar'>Unlock the potential of personalized communication with our custom WhatsApp templates. Design templates that resonate with your audience and enhance engagement in a meaningful way. If you haven't created one yet, now's the perfect time! Click the button below to start designing your unique template and elevate your messaging experience today!</p>
                    <div className="btcre">
                        <button className="button-create" onClick={handleCreateNewTemplate}>
                            Create WhatsApp Template
                        </button>
                    </div>
                </div>
                <hr />
                <h4 style={{ padding: '10px 20px', color: '#00417d' }}>Send Template</h4>
                <div className="dropdown-preview-container">
                    <h6>Select Your Template</h6>
                    <div className="dropdown-container-mod">
                        <select
                            className="dropdown-mod"
                            value={selectedTemplateId}
                            onChange={handleDropdownChange}
                        >
                            <option value="">Select Whatsapp Template</option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <ModifyTemplate
                    code={selectedCode || ''} // Default to an empty string if no code
                    name={selectedTemplate?.name || ''} // Default to an empty string if no template selected
                    status={selectedTemplate?.wa_template_status || 'PENDING'} // Default to 'PENDING'
                    category={selectedTemplate?.wa_template_category || 'General'} // Default to 'General'
                    campaign_id={templateId}
                    headerHandlerId={selectedHeaderHandlerId || ''} // Default to an empty string
                />

            </div>
            {loading && (
                <div className="loader-container">
                    <div className="loader"><span>

                    </span></div>

                </div>
            )}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

        </>
    );
};

export default CreateWhatsappTemplate;