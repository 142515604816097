import React, { useState, useEffect } from 'react';
import { getIncomingMessages } from '../ApiService/ApiService'; // Adjust the import path
import './Messages.css';

const Messages = () => {
    const [messages, setMessages] = useState([]); // State to store fetched messages
    const [loading, setLoading] = useState(false); // Loading indicator
    const [error, setError] = useState(null); // Error state
    const [page, setPage] = useState(1); // Page number for pagination
    const [hasMore, setHasMore] = useState(true); // Flag to check if more data is available
    const [isRequestInProgress, setIsRequestInProgress] = useState(false); // To prevent multiple requests at the same time

    const fetchMessages = async () => {
        if (isRequestInProgress) return; // Prevent duplicate requests if a request is already in progress

        setIsRequestInProgress(true); // Mark request as in progress
        setLoading(true); // Start loading

        try {
            const data = await getIncomingMessages(page, 10); // Fetch messages with pagination

            // console.log('API response data:', data); // Log the raw API response

            if (data.length > 0) {
                // Filter out any duplicate messages based on the ID
                const newMessages = data.filter(
                    (newMessage) => !messages.some((message) => message.id === newMessage.id)
                );
                // console.log('Filtered new messages:', newMessages); // Log the filtered messages

                if (page === 1) {
                    setMessages(newMessages); // Reset messages on the first page
                } else {
                    setMessages((prevMessages) => [...prevMessages, ...newMessages]); // Append new messages on subsequent pages
                }
            } else {
                setHasMore(false); // Disable Load More if no data is returned
            }
        } catch (err) {
            setError(err.message); // Handle errors
        } finally {
            setLoading(false); // Stop loading
            setIsRequestInProgress(false); // Mark request as completed
        }
    };

    useEffect(() => {
        fetchMessages(); // Fetch messages when page changes
    }, [page]); // Depend on 'page' to trigger data fetching when the page changes

    // Render loading state
    if (loading && page === 1) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                Error: {error}
            </div>
        );
    }

    // Function to handle null values
    const handleNullValue = (value) => {
        return value === null || value === undefined ? '-' : value;
    };

    // Handle Load More button click
    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1); // Increment page number
    };

    // Render table with messages
    return (
        <div className="containers mt-3">
            <h4 style={{ paddingTop: '0px', color: '#00417d' }}>Messages</h4>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="tbhd">
                        <tr>
                            <th>Contact Name</th>
                            <th>WA ID</th>
                            <th>Message Type</th>
                            <th>Message Body</th>
                            <th>Media ID</th>
                            <th>Mime Type</th>
                            <th>Timestamp</th>
                            <th>Filename</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.length > 0 ? (
                            messages.map((message, index) => (
                                <tr key={message.id} className="message-row">
                                    <td>{handleNullValue(message.contact_name)}</td>
                                    <td>{handleNullValue(message.wa_id)}</td>
                                    <td className="message-type">{handleNullValue(message.message_type)}</td>
                                    <td>{handleNullValue(message.message_body)}</td>
                                    <td>{handleNullValue(message.media_id)}</td>
                                    <td>{handleNullValue(message.mime_type)}</td>
                                    <td>{handleNullValue(new Date(message.timestamp).toLocaleString())}</td>
                                    <td>{handleNullValue(message.filename)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">No messages found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Load More Button */}
            {hasMore && (
                <div className="d-flex justify-content-end mt-3">
                    <button
                        className="btn btn-primary"
                        onClick={handleLoadMore}
                        disabled={loading || isRequestInProgress}>
                        {loading || isRequestInProgress ? 'Loading...' : 'Load More'}
                    </button>
                </div>
            )}

            {/* Disable button when no more data is available */}
            {!hasMore && (
                <div className="alert alert-info text-center mt-3" role="alert">
                    No more messages to load.
                </div>
            )}
        </div>
    );
};

export default Messages;
