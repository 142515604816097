import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '../ThemeContext'; // Correct import
import axios from 'axios'; // Import axios for fetching IP address
import './Navbar.css';
import logo from '../../assets/whatsapp-logo.svg'; // Adjust the path to your logo file
import { getUserBalance } from '../ApiService/ApiService';
import { Link } from 'react-router-dom';
import TokenService from '../Tokens/tokenservice'; // Adjust the path based on your project structure


const Navbar = () => {
    const { theme, toggleTheme } = useTheme();
    const [dateTime, setDateTime] = useState(new Date());
    const [ipAddress, setIpAddress] = useState('');
    const [balance, setBalance] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        getUserBalance()
            .then((data) => {
                // console.log('User Balance:', data);
                setBalance(data.balance); // Assume the API returns a `balance` field
            })
            .catch((err) => {
                console.error(err.message);
                setError('Failed to fetch user balance. Redirecting to login...');
            });
    }, []);

    useEffect(() => {
        // Update date and time every second
        const timer = setInterval(() => {
            setDateTime(new Date());
        }, 1000);

        // Fetch the IP address
        axios.get('https://api.ipify.org?format=json')
            .then(response => {
                setIpAddress(response.data.ip);
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, []);

    const formattedDate = useMemo(() => dateTime.toLocaleDateString(), [dateTime]);
    const formattedTime = useMemo(() =>
        dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        [dateTime]
    );

    return (
        <>
        <nav className={`navbar ${theme}`} style={{ position: 'fixed', zIndex: '999' }}>
            <div className="navbar-left">
                <img src={logo} alt="WhatsApp Logo" className="navbar-logo" />
                <h1 className="navbar-title">IISMS-Whatsapp</h1>
            </div>
            <div className="navbar-menu">
                <a href="#ip" className="menu-item">IP: {ipAddress}</a>
                <a href="#datetime" className="menu-item">
                    Date: {formattedDate} Time: {formattedTime}
                </a>
                <a href="#balance" className="menu-item">
                    {error ? <p>{error}</p> : <p> Balance : ₹{balance}</p>}
                </a>
                {/* Link for Messages */}
                {/* <Link to="/main/messages" className="menu-item">Messages</Link> */}
                <a href="#help" className="menu-item">Help</a>
                {/* Link for Logout */}
                    <a
                        href="/login"
                        className="menu-item"
                        style={{ color: "red" }}
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default anchor behavior
                            TokenService.clearToken(); // Clear the token
                            window.location.href = "/login"; // Redirect to the login page
                        }}
                    >
                        Logout
                    </a>

            </div>
            <div className="toggle-container">
                <input
                    type="checkbox"
                    id="toggle"
                    className="custom-toggle"
                    onChange={toggleTheme}
                    checked={theme === 'dark'}
                />
            </div>
                <div className="top-bar">
                    {/* <a href="#ip" className="menu-item">IP: {ipAddress}</a>
                    <a href="#datetime" className="menu-item">
                        Date: {formattedDate} Time: {formattedTime}
                    </a> */}
                    <a href="#balance" className="menu-item">
                        {error ? <p>{error}</p> : <p> Balance : ₹{balance}</p>}
                    </a>

                    <a href="#help" className="menu-item">Help</a>
                    {/* Link for Logout */}
                    <button className='loginbn'>
                    <a href="/login" className="menu-item " style={{ color: "red" }} >Logout</a>
                    </button>
                </div>
        </nav>
       
</>
    );
};

export default Navbar;
