import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './TemplateReport.css';
import SentMessagesGraph from '../Charts/SentMessagesGraph';
import SpendingChart from '../Charts/SpendingChart';
import ResponseTimeChart from '../Charts/ResponseTimeChart';
import LocationDistributionMap from '../Charts/LocationDistributionMap';
import CampaignComparisonChart from '../Charts/CampaignComparisonChart';
import { fetchReportData } from '../ApiService/ApiService'; 
import { getStatusData } from '../ApiService/ApiService'; 
import DoughnutChart from '../Charts/DoughnutChart'; // Adjust path as needed
import { useParams } from 'react-router-dom';

const gradientColors = [
    'linear-gradient(90deg, #6496DB, #6496DB)',  // Yellow to Orange
    'linear-gradient(90deg, #FFCD56, #FFCD56)',  // Green to Light Green
    'linear-gradient(90deg, #FF6384, #FF6384 )',  // Blue gradient for delivered
    'linear-gradient(90deg, #13AA52, #00662B)',
    'linear-gradient(90deg, #6851BB, #5C4CB9)',
];

const statusDisplayMap = {
    read: "READ",
    delivered: "DELIVERED",
    sent: "SENT",
    created: "PENDING", // Map 'created' to display as 'PENDING'
    failed: "FAILED",
};
const possibleStatuses = Object.keys(statusDisplayMap);

const TemplateReport = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const templateId = queryParams.get('campaign_id');
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusResponse, setStatusResponse] = useState(null); // For storing status response
    const [statusError, setStatusError] = useState(null); // For storing status error

    // Extract primary color for DoughnutChart
    const doughnutColors = gradientColors.map((color) => color.split(', ')[1].replace(')', ''));

    // Function to get count for each status
    const getStatusCount = (status) => {
        const message = reportData?.messages?.find((msg) => msg.status === status);
        return message ? message.count : 0; // Return count or 0 if not found
    };

    // Prepare doughnut chart data
    const doughnutData = possibleStatuses.map((status) => getStatusCount(status));
    const doughnutLabels = possibleStatuses.map((status) => statusDisplayMap[status]);
    // console.log("Campaign ID:", templateId);
    useEffect(() => {
        const getReportData = async () => {
            if (!templateId) {
                setError("Template ID is missing.");
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const data = await fetchReportData(templateId); // Fetch report data
                // console.log("Report Data:", data); // Log for debugging
                setReportData(data); // Set the report data

                // Find the first status with audience data
                const firstStatusWithAudience = possibleStatuses.find(
                    (status) => data.messages?.find((msg) => msg.status === status)?.count > 0
                );

                if (firstStatusWithAudience) {
                    // Fetch data for the first status with audience
                    const statusData = await getStatusData(templateId, firstStatusWithAudience);
                    // console.log("Default Status Response:", statusData); // Log for debugging
                    setStatusResponse(statusData); // Set default table data
                } else {
                    console.warn("No audience data found for any status.");
                }
            } catch (error) {
                console.error("Error fetching report data:", error);
                setError(error.response?.data?.message || "Error fetching report data");
            } finally {
                setLoading(false);
            }
        };

        getReportData();
    }, [templateId]);


    // Handler for card click
    const handleCardClick = async (status) => {
        try {
            const data = await getStatusData(templateId, status); // Call API from apiService
            // console.log("Status Response:", data); // Log data for inspection
            setStatusResponse(data);
            setStatusError(null); // Reset error
        } catch (error) {
            console.error("Error fetching status response:", error);
            setStatusError(error.response?.data?.message || "Error fetching status response");
            setStatusResponse(null); // Reset status response on error
        }
    };

    if (loading) {
        return <div>Loading report data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!reportData || !reportData.messages || reportData.messages.length === 0) {
        return <div>No data available for this template.</div>;
    }

    return (
        <>
            <div className="repfull">
                <div className='report-container'>
                    <h4 style={{ paddingTop: '0px', color: '#00417d' }}>Campaign Report</h4>
                    <div className="containersd">
                        <div className="box box1" id='fscrds'>
                            <h2 style={{fontWeight:'700'}}>DASHBOARD</h2>
                            <h1 style={{ color:'#FF6384'}}>₹20000</h1>
                            <p className='mb-0'>Total spending on this campaign</p>
                            <h1>81</h1>
                            <p className='mb-0'>Total audience in this campaign</p>
                            <button className="btn" id='thsunm'>
                                THE SUMMARY
                            </button>
                        </div>
                        <div className="box box2">
                            <LocationDistributionMap />
                        </div>
                        <div className="box box3">
                            <div className="doughnut-chart-container">
                                <h4 style={{ color: '#00417d' }}>Status Distribution</h4>
                                <div style={{ height: '300px', width: '300px', margin: '0 auto' }}>
                                    <DoughnutChart data={doughnutData} colors={doughnutColors} labels={doughnutLabels} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-container">
                        {possibleStatuses.map((status, index) => (
                            <div
                                key={index}
                                className="box"
                                id="dashcards"
                                onClick={() => handleCardClick(status)}
                                style={{
                                    cursor: 'pointer',
                                    background: gradientColors[index % gradientColors.length],
                                }}
                            >
                                <div className="d-flex align-items-left">
                                    <div>
                                        <h2 className="text-uppercase text-white">
                                            {statusDisplayMap[status]}
                                            {/* {status.toUpperCase()} */}
                                        </h2>
                                        <h4 className="text-white">
                                            Audience: <span>{getStatusCount(status)}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="tabling">
                        {statusResponse && (
                            <div className="status-response">
                                <h4 style={{ color: '#00417d' }}>Status Response</h4>
                                <div>
                                    <h5 style={{ color: '#00417d' }}>Message Details</h5>
                                    <div className="status-table-container">
                                        <table className="status-table">
                                            <thead >
                                                <tr>
                                                    <th>Recipient ID</th>
                                                    <th>Status Timestamp</th>
                                                    {/* <th>Created At</th> */}
                                                    <th>Updated At</th>
                                                    <th>Errors</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {statusResponse.messages && statusResponse.messages.map((msg, index) => (
                                                    <tr key={index}>
                                                        <td>{msg.recipient_id}</td>
                                                        <td>{new Date(msg.status_timestamp).toLocaleString()}</td>
                                                        {/* <td>{new Date(msg.created_at).toLocaleString()}</td> */}
                                                        <td>{new Date(msg.updated_at).toLocaleString()}</td>
                                                        <td>{msg.errors ? 'Message-Undeliverable' : 'None'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="box box1" id='tabsicar'>
                            <CampaignComparisonChart />

                        </div>
                    </div>

                    {statusError && <div className="error-message">Error: {statusError}</div>}
                </div>
            </div>
        </>
    );
};

export default TemplateReport;
