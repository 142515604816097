import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ModifyTemplate.css';
import axios from 'axios';
import Chatbox from '../Chatbox/Chatbox';
import { uploadMedia, sendMessage } from '../ApiService/ApiService';
import * as XLSX from 'xlsx';


const ModifyTemplate = ({ code, name, campaign_id }) => {
    const [headerText, setHeaderText] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [headerVariables, setHeaderVariables] = useState([]);
    const [bodyVariables, setBodyVariables] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isImageHeader, setIsImageHeader] = useState(false);
    const [isDocumentHeader, setIsDocumentHeader] = useState(false);
    const [isLocationHeader, setIsLocationHeader] = useState(false); // New State for Location Header
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [imageId, setImageId] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [modalMessage, setModalMessage] = useState("Your are sending templates again in same capaign create new campaign.");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    const [totalNumbers, setTotalNumbers] = useState(0);
    const [duplicateCount, setDuplicateCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [headerType, setHeaderType] = useState(null);
    const [variableValue, setVariableValue] = useState('');
    const resetTemplateData = () => {
        setIsImageHeader(false);
        setIsDocumentHeader(false);
        setIsLocationHeader(false);
        setHeaderText('');
        setHeaderVariables([]);
        setBodyText('');
        setBodyVariables([]);
        setFooterText('');
        setButtonText('');
        setSelectedImage(null);
        setImageId('');
        setDocumentId('');
        setLatitude('');
        setLongitude('');
        setLocationName('');
        setLocationAddress('');
    };
    useEffect(() => {
        if (code) {
            resetTemplateData();
            const components = JSON.parse(code)?.components || [];
            components.forEach((component) => {
                switch (component.type) {
                    case 'HEADER':
                        if (component.format === 'IMAGE') {
                            setHeaderType('IMAGE');
                            setIsImageHeader(true);                        
                            setIsDocumentHeader(false);
                            setIsLocationHeader(false);
                        } else if (component.format === 'DOCUMENT') {
                            setHeaderType('DOCUMENT');
                            setIsDocumentHeader(true);                   
                            setIsImageHeader(false);
                            setIsLocationHeader(false);
                        } else if (component.format === 'LOCATION') {
                            setHeaderType('LOCATION');
                            setIsLocationHeader(true); // Set to true if the header is location
                            setIsImageHeader(false);
                            setIsDocumentHeader(false);
                        } else {
                            setHeaderType(null);
                            setIsImageHeader(false);
                            setIsDocumentHeader(false);
                            setIsLocationHeader(false);
                            setHeaderText(component.text || '');
                            setHeaderVariables(component.example?.header_text || []);
                        }
                        break;
                    case 'BODY':
                        setBodyText(component.text || '');
                        setBodyVariables(component.example?.body_text?.flat() || []);
                        break;
                    case 'FOOTER':
                        setFooterText(component.text || '');
                        break;
                    case 'BUTTONS':
                        setButtonText(component.buttons.map((b) => b.text).join(', '));
                        break;
                    default:
                        break;
                }
            });
        }
    }, [code]);

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     setSelectedImage(file);
    //     uploadFile(file, 'image');
    // };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file); // Create a preview URL
            setSelectedImage(imageURL); // Store the URL to pass to the Chatbox
            uploadFile(file, 'image'); // Upload logic remains the same
        }
    };
    const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        setSelectedDocument(file);
        uploadFile(file, 'document');
    };

    // const uploadFile = async (file, type) => {
    //     try {
    //         const response = await uploadMedia(file);
    //         const mediaId = response.data.data.id;
    //         if (type === 'image') setImageId(mediaId);
    //         else if (type === 'document') setDocumentId(mediaId);
    //     } catch (error) {
    //         console.error('Upload Error:', error);
    //     }
    // };
    const uploadFile = async (file, type) => {
        try {
            const response = await uploadMedia(file);
            if (response && response.data && response.data.id) {
                const mediaId = response.data.id;
                if (type === 'image') setImageId(mediaId);
                else if (type === 'document') setDocumentId(mediaId);
            } else {
                console.error('Invalid response structure:', response);
            }
        } catch (error) {
            console.error('Upload Error:', error);
        }
    };

    const validateNumbers = (numbers) => {
        const invalidNumbers = [];
        const seen = new Set();
        const validNumbers = [];
        const duplicates = new Set();

        numbers.forEach((num) => {
            if (!/^\d{10}$/.test(num)) {
                invalidNumbers.push(num); // Invalid number
            } else if (seen.has(num)) {
                duplicates.add(num); // Duplicate number
            } else {
                seen.add(num);
                validNumbers.push(num); // Valid number
            }
        });

        return { validNumbers, invalidNumbers, duplicates };
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setIsLoading(true); // Show loading bar
            setError(''); // Clear previous errors
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const binaryStr = event.target.result;
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0]; // Get the first sheet
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert sheet to JSON
                    const numbers = data.map((row) => row[0]).filter((num) => !!num); // Extract first column & remove empty

                    setTimeout(() => {
                        const { validNumbers, invalidNumbers, duplicates } = validateNumbers(numbers);

                        setTotalNumbers(numbers.length);
                        setDuplicateCount(duplicates.size);
                        setIncorrectCount(invalidNumbers.length);

                        if (invalidNumbers.length > 0 || duplicates.size > 0) {
                            const confirmation = window.confirm(
                                `Warning: Found ${invalidNumbers.length} invalid numbers and ${duplicates.size} duplicate numbers.\nDo you want to remove them and proceed with valid numbers?`
                            );

                            if (confirmation) {
                                // Filter out invalid and excess duplicate numbers (retain only one instance of each duplicate)
                                const cleanedNumbers = Array.from(new Set(validNumbers)); // Ensure only unique numbers
                                setMobileNumber(cleanedNumbers.join(',')); // Set valid numbers
                            } else {
                                setMobileNumber(''); // Clear mobile numbers if user cancels
                            }
                        } else {
                            setMobileNumber(validNumbers.join(',')); // Set valid numbers directly if no errors
                        }

                        setIsLoading(false); // Stop loading
                    }, 2000); // Simulate 2-second delay for processing
                } catch (error) {
                    console.error('Error processing file:', error);
                    alert('Error processing file. Please ensure it is a valid Excel file.');
                    setIsLoading(false); // Stop loading if there's an error
                }
            };
            reader.readAsBinaryString(file);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Split and validate mobile numbers
        const mobileNumbers = mobileNumber.split(',').map(num => num.trim());
        for (let num of mobileNumbers) {
            if (!/^[6-9]\d{9}$/.test(num)) {
                alert(`Please enter a valid 10-digit Indian mobile number: ${num}`);
                return;
            }
        }

        setStatuses([]); // Reset statuses

        // Prepare header parameters
        let headerParameters = [];
        if (isImageHeader) {
            if (imageId) {
                headerParameters = [{ type: 'image', image: { id: imageId } }];
            } else {
                alert("Please upload an image for the header.");
                return;
            }
        } else if (isDocumentHeader) {
            if (documentId) {
                headerParameters = [{ type: 'document', document: { id: documentId } }];
            } else {
                alert("Please upload a document for the header.");
                return;
            }
        } else if (isLocationHeader) {
            if (latitude && longitude && locationName && locationAddress) {
                headerParameters = [{
                    type: 'location',
                    location: {
                        latitude,
                        longitude,
                        name: locationName,
                        address: locationAddress,
                    },
                }];
            } else {
                alert("Please fill all location details.");
                return;
            }
        } else {
            headerParameters = headerVariables.map((variable) => ({ type: 'text', text: variable }));
        }

        // Prepare body parameters
        const bodyParameters = bodyVariables.map((variable) => ({ type: 'text', text: variable }));

        // Create the payload
        const payload = {
            to: mobileNumbers, // Send all numbers in a single request
            templateName: name,
            templateData: {
                name,
                language: { code: 'en_US' },
                components: [
                    { type: 'HEADER', parameters: headerParameters },
                    { type: 'BODY', parameters: bodyParameters },
                ],
            },
            campaign_id,
        };

        // Send the request
        try {
            const response = await sendMessage(payload); // Single API request
            if (!response.ok) {
                if (response.status === 400) {
                    setModalMessage("Error 400: Bad request. Please check the input fields and try again.");
                } else {
                    setModalMessage(`Template has been successfully sent in the background. You can proceed with another activity.`);
                }
                setShowModal(true);
              return
            }

    const contentType = response.headers.get('Content-Type');
    let data;
    if (contentType && contentType.includes('application/json')) {
        data = await response.json();
    } else {
        data = await response.text();  // Handle non-JSON response as text
    }
            // console.log("Response data:", data);
            setModalMessage("Template sent successfully!");
            setShowModal(true);
        } catch (error) {
            console.error("Error occurred:", error);

            // If you don't want to include the number in the status when there's an error, 
            // you can simply set the status for valid numbers, or set a general failed message.
            setStatuses([{ status: 'Failed', error: error.message || 'Unknown error' }]);

            // Set the modal message to inform the user about the error without exposing the number
            setModalMessage("An error occurred while sending the template. Please try again.");

            // Show the modal to notify the user
            setShowModal(true);
        }
    };



    const handleModalClose = () => {
        setShowModal(false); // Close the modal

        setModalMessage("Template has been successfully sent in the background. You can proceed with another activity.");

        navigate('/main/dashboard'); // Navigate to home page
    };
    const handleHeaderVariableChange = (index, newValue) => {
        setHeaderVariables((prevVariables) => {
            const updatedVariables = [...prevVariables];
            updatedVariables[index] = newValue; // Update the variable at the specified index
            return updatedVariables;
        });
    };
    const handleBodyVariableChange = (index, newValue) => {
        setBodyVariables((prevVariables) => {
            const updatedVariables = [...prevVariables];
            updatedVariables[index] = newValue; // Update the variable at the specified index
            return updatedVariables;
        });
    };

    return (
        <div className="modify-template">
            <div className="mainheadingleft">
                <form onSubmit={handleSubmit} className="modify-template-container">
                    {/* <h3>Modify WhatsApp Template</h3> */}

                    {/* Header Section */}
                    <div className="box">
                        <h5>Header</h5>
                        {isImageHeader ? (
                            <>
                                <p>Select an Image for the Header:</p>
                                <input type="file" accept="image/jpeg" onChange={handleImageChange} />
                            </>
                        ) : isDocumentHeader ? (
                            <>
                                <p>Select a PDF Document for the Header:</p>
                                <input type="file" accept="application/pdf" onChange={handleDocumentChange} />
                            </>
                        ) : isLocationHeader ? ( // Check if it's a location header
                            <>
                                <p>Location Details</p>
                                <input
                                    className='sendinput'
                                    type="text"
                                    value={latitude}
                                    onChange={(e) => setLatitude(e.target.value)}
                                    placeholder="Enter Latitude"
                                />
                                <input
                                    className='sendinput'
                                    type="text"
                                    value={longitude}
                                    onChange={(e) => setLongitude(e.target.value)}
                                    placeholder="Enter Longitude"
                                />
                                <input
                                    className='sendinput'
                                    type="text"
                                    value={locationName}
                                    onChange={(e) => setLocationName(e.target.value)}
                                    placeholder="Enter Location Name"
                                />
                                <input
                                    type="text"
                                    className='sendinput'
                                    value={locationAddress}
                                    onChange={(e) => setLocationAddress(e.target.value)}
                                    placeholder="Enter Address"
                                />
                            </>
                        ) : (
                            <>

                                <textarea
                                    className='sendinput'
                                    value={headerText}
                                    onChange={(e) => setHeaderText(e.target.value)}
                                    placeholder="No Header Text"
                                    disabled
                                    style={{ height: 'auto' }}
                                />

                                {headerVariables.map((variable, index) => (
                                    <input
                                        className="sendinput"
                                        key={index}
                                        type="text"
                                        value={variable}
                                        onChange={(e) => handleHeaderVariableChange(index, e.target.value)}
                                        placeholder={`Header Variable ${index + 1}`}
                                    />

                                ))}
                            </>
                        )}
                    </div>

                    {/* Body Section */}
                    <div className="box">
                        <h5>Body</h5>
                        <textarea
                            className='sendinput'
                            value={bodyText}
                            onChange={(e) => setBodyText(e.target.value)}
                            placeholder="Body Text"
                            disabled
                            style={{ height: 'auto' }}
                        />
                        {bodyVariables.map((variable, index) => (
                            <input
                                className='sendinput'
                                key={index}
                                type="text"
                                value={variable}
                                onChange={(e) => handleBodyVariableChange(index, e.target.value)}
                                placeholder={`Body Variable ${index + 1}`}
                            />
                        ))}
                    </div>

           
                    <div className="box">
                        <h5>Recipient Mobile Number</h5>
                        <input
                            className="sendinput"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileUpload}
                        />
                        <br />
                        <span style={{ paddingTop: '10px' }}>
                            Upload an Excel file containing mobile numbers in the first column.
                        </span>
                        <br />
                        {isLoading ? (
                            <div style={{ marginTop: '10px' }}>
                                <div className="loading-bar">
                                    <div className="loading-progress"></div>
                                </div>
                                <p>Processing file...</p>
                            </div>
                        ) : (
                            totalNumbers > 0 && (
                                <div style={{ marginTop: '10px' }}>
                                    <p>Total Numbers: {totalNumbers}</p>
                                    <p>Invalid Numbers: {incorrectCount}</p>
                                    <p>Duplicate Numbers: {duplicateCount}</p>
                                    <p>Valid Numbers: {totalNumbers - incorrectCount - duplicateCount}</p>
                                </div>
                            )
                        )}
                        {/* <div>
                            <p>Valid Numbers: {mobileNumber}</p>
                        </div> */}
                    </div>
                    <div className="button-container">
                        <button type="submit" className='submit-button'>Send</button>
                    </div>
                </form>
            </div>
            <div className="box chat-previewmod">
                <h4 className='hh44'>Live Chat Preview</h4>
                <Chatbox
                    headerType={headerType}
                    headerText={headerText}
                    headerVariables={headerVariables}
                    bodyText={bodyText}
                    bodyVariables={bodyVariables}
                    footerText={footerText}
                    buttonText={buttonText}
                    headerImage={selectedImage} // Pass the image to Chatbox
                />
            </div>
       

            {showModal && (
                <div className="modal-overlaymo">
                    <div className="modal-contentmo">
                        <h5>{modalMessage}</h5>
                        <button className='btn-primary' onClick={handleModalClose}>OK</button>
                    </div>
                </div>
            )}
        </div>

    );
};

export default ModifyTemplate;