import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import MainLayout from './components/MainLayout/MainLayout';
import Login from './components/Login/LogIn';

import Content1 from './components/Content1';
import { ThemeProvider } from './components/ThemeContext';
import SelectTemplate from './components/SelectTemplate/SelectTemplate';
import CreateWhatsappTemplate from './components/CreateWhatsappTemplate/CreateWhatsappTemplate';
import ModifyTemplate from './components/ModifyTemplate/ModifyTemplate';
import TemplateReport from './components/TemplateReport/TemplateReport';
import Summary from './components/Summary/Summary';
import FileUpload from './components/FileUpload';
import Authotp from './components/Authotp/Authotp';
import NextComponent from './components/NextComponent/NextComponents';
import CreateTemplate from './components/NextComponent/CreateTemplate';
import Manager from './components/Manager';
import Manager1 from './components/Manager1';
import Manager2 from './components/Manager2';
import Manager3 from './components/Manager3';
import Dashboard from './components/Dashboard/Dashboard';
import TemplatePreviews from './components/TemplatesPre/TemplatePreviews';
import CustomUtility from './components/NextComponent/CustomUtility';
import Messages from './components/Messages/Messages';
import DownMedia from './components/DownMedia';
import { getToken } from './components/ApiService/ApiService'; // Adjust the path as necessary

// PrivateRoute Component that checks the token before rendering the route
const PrivateRoute = ({ element: Component }) => {
    const token = getToken(); // Get token from local storage or wherever it's stored
    // console.log("Checking token...");

    if (!token) {
        // console.log("No token found, redirecting to login.");
        return <Navigate to="/login" replace />;
    }

    // Decode the token to check for expiration
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
            // console.log("Token expired, redirecting to login.");
            return <Navigate to="/login" replace />;
        }
        // console.log("Token valid, allowing access.");
    } catch (error) {
        // console.log("Error decoding token, redirecting to login.");
        return <Navigate to="/login" replace />;
    }

    // If token is valid, render the component
    return <Component />;
};

const AppRouter = () => {
    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    {/* Redirect to main if token is valid, else redirect to login */}
                    <Route path="/" element={<Navigate to="/login" />} />

                    {/* Public routes */}
                    <Route path="/login" element={<Login />} />
                  
                   
                    {/* Protected routes */}
                    <Route path="/main" element={<PrivateRoute element={MainLayout} />}>
                        {/* Protected sub-routes inside /main */}
                        <Route index element={<Navigate to="dashboard" />} />
                        <Route path="Home" element={<PrivateRoute element={Content1} />} />
                        <Route path="create-template" element={<PrivateRoute element={SelectTemplate} />} />
                        <Route path="send-template" element={<PrivateRoute element={CreateWhatsappTemplate} />} />
                        <Route path="template_report" element={<PrivateRoute element={TemplateReport} />} />
                        <Route path="summary" element={<PrivateRoute element={Summary} />} />
                        <Route path="authotp" element={<PrivateRoute element={Authotp} />} />
                        <Route path="createmplate" element={<PrivateRoute element={NextComponent} />} />
                        <Route path="Create_Template" element={<PrivateRoute element={CreateTemplate} />} />
                        <Route path="utility_custom_template" element={<PrivateRoute element={CustomUtility} />} />
                        <Route path="site_under_construction" element={<PrivateRoute element={Manager} />} />
                        <Route path="under_construction" element={<PrivateRoute element={Manager1} />} />
                        <Route path="site_construction" element={<PrivateRoute element={Manager2} />} />
                        <Route path="site_comming_soon" element={<PrivateRoute element={Manager3} />} />
                        <Route path="dashboard" element={<PrivateRoute element={Dashboard} />} />
                        <Route path="templates" element={<PrivateRoute element={TemplatePreviews} />} />
                        <Route path="messages" element={<PrivateRoute element={Messages} />} />
                    </Route>

                    {/* Other protected routes */}
                    <Route path="/template-page" element={<PrivateRoute element={SelectTemplate} />} />
                    <Route path="/mediartr" element={<PrivateRoute element={DownMedia} />} />
                    <Route path="modify_Whatsapp_Template" element={<PrivateRoute element={ModifyTemplate} />} />
                    <Route path="upload_file" element={<PrivateRoute element={FileUpload} />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default AppRouter;
