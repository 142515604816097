import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Correct import for jwt-decode
import './DashCards.css';
import { fetchTemplateCounts } from '../ApiService/ApiService'; // Import the API function

const DashCards = () => {
    const [cardData, setCardData] = useState([]); // All fetched data
    const [filteredData, setFilteredData] = useState([]); // Data after applying the filter
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('Overall'); // Default filter is 'all'

    // Function to retrieve and decode userId from token
    const getUserIdFromToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id; // Adjust based on your token structure
            } catch (err) {
                console.error('Error decoding token:', err);
            }
        }
        return null;
    };


    const fetchData = async () => {
        try {
            const templateCounts = await fetchTemplateCounts();
            setCardData(templateCounts); // Set the template counts as card data
            setFilteredData(templateCounts); // Initially set filtered data to all cards
            setLoading(false);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data on component mount
    }, []);



    useEffect(() => {
        // Filter data based on the selected filter (time_range)
        if (filter === 'all') {
            setFilteredData(cardData); // Show all data
        } else {
            const filtered = cardData.filter((card) => card.time_range === filter);
            setFilteredData(filtered); // Apply filter based on time_range
        }
    }, [filter, cardData]); // Re-run the filter logic when filter or cardData changes

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    return (
        <div className="dashboard">
            <div className="filter-container">
            <div><h3>Templates Data</h3></div>
            <div>
                <button
                    onClick={() => handleFilterChange('Overall')}
                    className={filter === 'Overall' ? 'active' : ''}
                >
                    Overall
                </button>
                <button
                    onClick={() => handleFilterChange('Last 30 Days')}
                    className={filter === 'Last 30 Days' ? 'active' : ''}
                >
                    Last 30 Days
                </button>
                <button
                    onClick={() => handleFilterChange('Last 7 Days')}
                    className={filter === 'Last 7 Days' ? 'active' : ''}
                >
                    Last 7 Days
                </button>
                </div>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="dashboard-cards">
                    {filteredData.length > 0 ? (
                        filteredData.map((card, index) => {
                            {/* console.log('Status:', card.wa_template_status); // Verify status values */}

                            // Determine the status class based on wa_template_status
                            let statusClass = '';
                            if (card.wa_template_status === 'APPROVED') {
                                statusClass = 'status-approved';
                            } else if (card.wa_template_status === 'PENDING') {
                                statusClass = 'status-pending';
                            }

                            return (
                                <div key={index} className="card dashccc">
                                    <div className="card-content">
                                        <div className="left-section">
                                            <h3>{card.wa_template_category}</h3>
                                            <p className={`status-text ${statusClass}`}>
                                                 {card.wa_template_status}
                                            </p>
                                        </div>
                                        <div className="right-section">
                                            <p className="headcount" style={{fontSize:'2rem',fontWeight:'600'}}>{card.count}</p>
                                            <p>Audience</p>
                                        </div>
                                    </div>
                                    <hr style={{margin:'0',padding:'0'}} />
                                    <div className="bottom-section">
                                        <p style={{margin:'0',padding:'0'}}> {card.time_range}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p>No data available</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default DashCards;
