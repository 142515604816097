import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DownMedia = () => {
    const [data, setData] = useState(null);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const accessToken = "YOUR_ACCESS_TOKEN";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://graph.facebook.com/v21.0/8817193911694670', {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                if (response.data && response.data.url) {
                    setData(response.data);

                    const mediaUrl = response.data.url;
                    const proxyUrl = `http://localhost:3000/proxy?url=${encodeURIComponent(mediaUrl)}`;

                    const imageResponse = await axios.get(proxyUrl, { responseType: 'blob' });
                    const imageUrl = URL.createObjectURL(imageResponse.data);
                    setImage(imageUrl);
                } else {
                    throw new Error('Media URL not found in the response');
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError(err.message || 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <h1>Facebook API Data</h1>
            {data && <pre>{JSON.stringify(data, null, 2)}</pre>}

            {image && (
                <div>
                    <h2>Downloaded Image:</h2>
                    <img src={image} alt="Downloaded" style={{ maxWidth: '100%' }} />
                </div>
            )}
        </div>
    );
};

export default DownMedia;
