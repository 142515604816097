import React, { useState } from 'react';
import './Authotp.css'; // Import your styles
import { FaBullhorn, FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import axios from 'axios';
import TokenService from '../Tokens/tokenservice';
const Authotp = () => {
    const [templateName, setTemplateName] = useState('authenticate');
    const [language, setLanguage] = useState('English');
    const [selectedOption, setSelectedOption] = useState('oneTapAutoFill');
    const [appDetails, setAppDetails] = useState([{ packageName: 'www.google.com', signatureHash: 'googlesiteh' }]);
    const [addSecurity, setAddSecurity] = useState(false);
    const [addExpiry, setAddExpiry] = useState(false);
    const [expiryTime, setExpiryTime] = useState(10); // Default expiry time
    const [isCustomValidityPeriod, setIsCustomValidityPeriod] = useState(false);
    const [validityPeriod, setValidityPeriod] = useState('10'); // Default 10 minutes
    const [autoFillButtonText, setAutoFillButtonText] = useState('Autofill');
    const [copyCodeButtonText, setCopyCodeButtonText] = useState('Copy code');

    const handleToggleCustomPeriod = () => {
        setIsCustomValidityPeriod(!isCustomValidityPeriod); // Toggle the state
    };

    const handleAddApp = () => {
        setAppDetails([...appDetails, { packageName: '', signatureHash: '' }]);
    };

    const handleRemoveApp = (index) => {
        const updatedAppDetails = appDetails.filter((_, i) => i !== index);
        setAppDetails(updatedAppDetails);
    };

    const handleAppChange = (index, field, value) => {
        const updatedAppDetails = appDetails.map((app, i) =>
            i === index ? { ...app, [field]: value } : app
        );
        setAppDetails(updatedAppDetails);
    };

    const refreshToken = async () => {
        try {
            const response = await axios.post('https://api.example.com/refresh-token', {
                // Provide necessary details to refresh the token
            });
            localStorage.setItem('token', response.data.token);
            return response.data.token;
        } catch (error) {
            console.error('Error refreshing token:', error.message);
            throw error;
        }
    };

    const handleSubmit = async () => {
        const components = [];

        // Add components based on the selections
        if (addSecurity) {
            components.push({
                type: "BODY",
                add_security_recommendation: true,
            });
        }
        if (addExpiry) {
            components.push({
                type: "FOOTER",
                code_expiration_minutes: expiryTime,
            });
        }

        // Prepare buttons based on the selected option
        if (selectedOption === 'copyCode') {
            components.push({
                type: "BUTTONS",
                buttons: [
                    {
                        type: "OTP",
                        otp_type: "COPY_CODE",
                        text: copyCodeButtonText, // Use state variable
                    },
                ],
            });
        }

        // Add button data for the oneTapAutoFill option
        if (selectedOption === 'oneTapAutoFill') {
            components.push({
                type: "BUTTONS",
                buttons: appDetails.map(app => ({
                    type: "OTP",
                    otp_type: "ONE_TAP",
                    text: copyCodeButtonText, // Use state variable
                    autofill_text: autoFillButtonText, // Use state variable
                    package_name: app.packageName,
                    signature_hash: app.signatureHash,
                })),
            });
        }

        // Prepare the JSON data
        const jsonData = {
            name: templateName,
            code: {
                name: templateName,
                language: language === 'English' ? 'en_US' : 'es_ES', // Example language mapping
                category: "AUTHENTICATION",
                components,
            },
            userid: "13", // Example user ID, adjust accordingly
        };

        console.log("Generated JSON data:", JSON.stringify(jsonData, null, 2)); // Print the JSON in a pretty format

        // Get the token from local storage
        let token = TokenService.getToken();

        // If token is not available or expired, refresh the token
        if (!token) {
            token = await refreshToken();
        }

        // Make the API call to create the WhatsApp template
        try {
            const response = await axios.post(
                'https://api.lohith.in:3007/api/v1/wa/create_wa_template',
                jsonData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log("API Response:", response.data);
        } catch (error) {
            console.error('Error creating WhatsApp template:', error.message);
        }
    };


    return (
        <div className="auth-template-container">
            <div className="firsthead">
                <div className="icon-container">
                    <FaBullhorn className="icon1" />
                </div>
                <div className="text-container">
                    <h2 className='hhh2'>{templateName} • {language}</h2>
                    <p>{templateName} • Custom</p>
                </div>
            </div>
            <div className="auth-template-header">
                <h3>{templateName} • {language}</h3>
                <button className="auth-delete-button">Delete template</button>
            </div>

            <div className="auth-template-section">
                <label>Template name and language</label>
                <div className="auth-input-group">
                    <input
                        type="text"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        maxLength={512}
                        className="auth-template-name-input"
                    />
                    <select
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="auth-language-select"
                    >
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                        {/* Add more languages as needed */}
                    </select>
                </div>
            </div>

            <div className="auth-template-section">
                <label>Code delivery setup</label>
                <div className="auth-radio-group">
                    <label className="auth-radio-option">
                        <input
                            type="radio"
                            value="zeroTapAutoFill"
                            checked={selectedOption === 'zeroTapAutoFill'}
                            onChange={() => setSelectedOption('zeroTapAutoFill')}
                            className="auth-radio-input"
                        />
                        Zero-tap auto-fill
                        <p>This is recommended as the easiest option for your customers. Zero-tap will automatically send the code without requiring your customer to tap a button.</p>
                    </label>
                    <label className="auth-radio-option">
                        <input
                            type="radio"
                            value="oneTapAutoFill"
                            checked={selectedOption === 'oneTapAutoFill'}
                            onChange={() => setSelectedOption('oneTapAutoFill')}
                            className="auth-radio-input"
                        />
                        One-tap auto-fill
                        <p>The code sends to your app when customers tap the button. A copy code message will be sent if auto-fill isn’t possible.</p>
                    </label>
                    <label className="auth-radio-option">
                        <input
                            type="radio"
                            value="copyCode"
                            checked={selectedOption === 'copyCode'}
                            onChange={() => setSelectedOption('copyCode')}
                            className="auth-radio-input"
                        />
                        Copy code
                        <p>Basic authentication with quick setup. Your customers copy and paste the code into your app.</p>
                    </label>
                </div>
            </div>

            {/* Conditionally render the App Setup section based on radio selection */}
            {(selectedOption === 'zeroTapAutoFill' || selectedOption === 'oneTapAutoFill') && (
                <div className="auth-template-section">
                    <label>App setup</label>
                    {appDetails.map((app, index) => (
                        <div key={index} className="auth-input-group">
                            <label>Package name</label>
                            <input
                                type="text"
                                value={app.packageName}
                                onChange={(e) => handleAppChange(index, 'packageName', e.target.value)}
                                className="auth-package-input"
                            />
                            <label>App signature hash</label>
                            <input
                                type="text"
                                value={app.signatureHash}
                                onChange={(e) => handleAppChange(index, 'signatureHash', e.target.value)}
                                maxLength={11}
                                className="auth-signature-input"
                            />
                            {index > 0 && (
                                <FaMinusCircle className="auth-remove-app-icon" onClick={() => handleRemoveApp(index)} />
                            )}
                        </div>
                    ))}
                    <button className="auth-add-app-button" onClick={handleAddApp}>
                        <FaPlusCircle /> Add another app
                    </button>
                </div>
            )}

            {/* Always render the Content section */}
            <div className="auth-content-section">
                <h4 className="auth-content-header">Content</h4>
                <p className="auth-content-description">Content for authentication message templates can’t be edited. You can add additional content from the options below.</p>
                <div className="auth-checkbox-group">
                    <label className="auth-checkbox-label">
                        <input
                            type="checkbox"
                            checked={addSecurity}
                            onChange={() => setAddSecurity(!addSecurity)}
                            className="auth-checkbox-input"
                        />
                        Add security recommendation
                    </label>
                </div>
                <div className="auth-checkbox-group">
                    <label className="auth-checkbox-label">
                        <input
                            type="checkbox"
                            checked={addExpiry}
                            onChange={() => setAddExpiry(!addExpiry)}
                            className="auth-checkbox-input"
                        />
                        Set code expiration time
                    </label>
                </div>
                {addExpiry && (
                    <input
                        type="number"
                        value={expiryTime}
                        onChange={(e) => setExpiryTime(e.target.value)}
                        className="auth-expiry-input"
                        placeholder="Set expiration time in minutes"
                    />
                )}
            </div>

            {/* Button for submitting the form */}
          

            {/* Auto-fill and copy code inputs */}
            <div className="auth-auto-fill-section">
                <label htmlFor="autoFillButton">Auto-fill</label>
                <input
                    type="text"
                    id="autoFillButton"
                    placeholder="Autofill"
                    maxLength={25}
                    className="auth-input auto-fill-input"
                    value={autoFillButtonText} // Use state value
                    onChange={(e) => setAutoFillButtonText(e.target.value)} // Update state on change
                />
                <small>{autoFillButtonText.length}/25</small>
            </div>
            <div className="auth-copy-code-section">
                <label htmlFor="copyCodeButton">Copy code</label>
                <input
                    type="text"
                    id="copyCodeButton"
                    placeholder="Copy code"
                    maxLength={25}
                    className="auth-input copy-code-input"
                    value={copyCodeButtonText} // Use state value
                    onChange={(e) => setCopyCodeButtonText(e.target.value)} // Update state on change
                />
                <small>{copyCodeButtonText.length}/25</small>
            </div>
            <button onClick={handleSubmit} className="auth-submit-button">
                Submit
            </button>
        </div>
    );
};

export default Authotp;
