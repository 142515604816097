import {jwtDecode} from 'jwt-decode';

const TokenService = {
    // Set token in localStorage
    setToken: (newToken) => {
        localStorage.setItem('Token', newToken); // Store token in localStorage
    },

    // Get token from localStorage
    getToken: () => {
        const token = localStorage.getItem('Token'); // Retrieve token
        if (!token || TokenService.isTokenExpired(token)) {
            console.warn('Token is expired or invalid.');
            return null;
        }
        return token;
    },

    // Clear token from localStorage
    clearToken: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('Token'); // Remove token from localStorage
    },

    // Check if token is expired
    isTokenExpired: (token) => {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            // console.log('Current Time (Unix):', currentTime);
            // console.log('Token Expiry (Unix):', decoded.exp);
            return decoded.exp <= currentTime; // Token is expired if current time is past expiration
        } catch (err) {
            console.error('Error decoding token:', err.message);
            return true; // Treat invalid tokens as expired
        }
    },

    // Get token expiry time in human-readable format
    getTokenExpiryTime: () => {
        const token = localStorage.getItem('Token'); // Retrieve token from localStorage
        if (!token) {
            console.warn('No token found');
            return null;
        }
        try {
            const decoded = jwtDecode(token);
            return new Date(decoded.exp * 1000).toLocaleString(); // Convert to readable format
        } catch (err) {
            console.error('Error decoding token:', err.message);
            return null;
        }
    }
};

// Function to get custom readable token expiry time
const getCustomReadableTime = () => {
    const token = localStorage.getItem('Token'); // Retrieve token
    if (!token) {
        console.warn('No token found in localStorage');
        return null;
    }
    try {
        const decoded = jwtDecode(token);
        const expiryTime = new Date(decoded.exp * 1000); // Convert expiry time to Date object
        const formatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
        return formatter.format(expiryTime); // Format date
    } catch (err) {
        console.error('Invalid token:', err.message);
        return null;
    }
};

// Example Usage
const customReadableTime = getCustomReadableTime();
// console.log('Token expires at:', customReadableTime);

export default TokenService;
